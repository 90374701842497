.part-form-builder {
  width: 100%;
  max-width: 842px;
  margin: 0 auto 136px;

  @media (max-width: 1679px) {
    max-width: calc(74.5vw - 400px);
  }

  @media (max-width: 1439px) {
    max-width: 682px;
  }

  @media (max-width: 1139px) {
    max-width: calc(100% - 100px);
  }

  @media (max-width: 904px) {
    max-width: calc(100% - 64px);
    margin-bottom: 56px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 27px;
    font-size: 36px;
    line-height: 46px;
    font-weight: $fw-light;

    @media (max-width: 904px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  #xp-formbuilder-successmessage{
    p{
      font-size: 22px;
      line-height: 40px;
      font-weight: $fw-light;

      @media (max-width: 904px) {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }

  form {
    .xp-form-input {
      border-bottom: 1px solid $solid-40;

      &:first-child {
        border-top: 1px solid $solid-40;

        @media (max-width: 599px) {
          border-color: $solid-100;
        }
      }

      @media (max-width: 599px) {
        border-color: $solid-100;
      }

      label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0;

        @media (max-width: 599px) {
          flex-direction: column;
        }

        span {
          width: 22%;
          padding: 24px 0 24px 16px;
          font-size: 20px;
          line-height: 28px;
          font-weight: $fw-light;

          @media (max-width: 904px) {
            font-size: 13px;
            line-height: 24px;
          }

          @media (max-width: 599px) {
            width: 100%;
            margin-bottom: 4px;
            padding: 12px 0 0 0;
          }
        }

        input[type="text"],
        input[type="email"],
        input[type="number"] {
          width: calc(78% - 32px);
          font-size: 24px;
          line-height: 34px;
          font-weight: 300;
          border: none;
          outline: none;
          border-bottom: 1px solid $solid-60;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border-radius: 0;

          @media (max-width: 904px) {
            font-size: 20px;
            line-height: 30px;
          }

          @media (max-width: 599px) {
            width: 100%;
            padding-bottom: 12px;
            border-bottom: none;
          }

          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $solid-60;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $solid-60;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $solid-60;
          }

          &:focus {
            outline: none;

            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: transparent;
              opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: transparent;
            }

            &::-ms-input-placeholder {
              /* Microsoft Edge */
              color: transparent;
            }
          }
        }
      }

      .required-star{
        color: $red-100;
        padding: 0 0 0 2px;
      }

      &--textarea {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        @media (max-width: 599px) {
          flex-direction: column;
        }

        label {
          width: 22%;

          @media (max-width: 599px) {
            width: 100%;
          }

          span {
            width: 100%;
          }
        }

        textarea {
          width: calc(78% - 32px);
          margin: 24px 0;

          @media (max-width: 599px) {
            width: 100%;
            margin-top: 12px;
            margin-bottom: 16px;
          }
        }
      }

      &--checkbox , &--checkboxes{
        label {
          padding: 24px 0 24px 16px;

          @media (max-width: 599px) {
            padding: 16px 0;
          }

          input {
            &[type="checkbox"] {
              position: absolute; // take it out of document flow
              opacity: 0; // hide it

              & + span {
                position: relative;
                width: 100%;
                margin-bottom: 16px;
                padding: 0 0 0 32px;
                cursor: pointer;
                color: inherit;
                font-size: 20px;
                line-height: 28px;
                font-weight: $fw-light;

                &:last-child {
                  margin-bottom: 0;
                }

                @media (max-width: 904px) {
                  font-size: 16px;
                  line-height: 24px;
                }
              }

              // Box.
              & + span:before {
                content: "";
                position: absolute;
                top: 7px;
                left: 0;
                vertical-align: text-top;
                width: 18px;
                height: 18px;
                border-radius: 3px;
                margin-right: 17px;
                border: 1px solid $solid-40;

                @media (max-width: 904px) {
                  top: 4px;
                }
              }

              // Box focus
              &:focus-visible + span:before {
                box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
              }

              // Disabled state span.
              &:disabled + span {
                color: #b8b8b8;
                cursor: auto;
              }

              // Disabled box.
              &:disabled + span:before {
                box-shadow: none;
                background: #ddd;
              }

              // Checkmark image
              &:checked + span:after {
                content: "";
                position: absolute;
                left: 0px;
                top: 7px;
                width: 18px;
                height: 18px;
                border-radius: 3px;
                background-color: $contrast-dark-1;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url("./../images/icons/check/check-24-white.svg");

                @media (max-width: 904px) {
                  top: 4px;
                }
              }
            }
          }
        }
      }

      &--radio, &--checkboxes {
        h6{
          padding: 24px 0 24px 16px;

          @media (max-width: 599px) {
            padding: 12px 0 16px;
          }

          span{
            font-size: 20px;
            line-height: 28px;
            font-weight: 300;

            @media (max-width: 904px) {
              font-size: 13px;
              line-height: 24px;
            }
          }
        }
      }

      &--radio{
        div {
          margin-bottom: 16px;
          padding-left: 16px;

          @media (max-width: 599px) {
            padding-left: 0;
          }

          &:last-child {
            margin-bottom: 24px;

            @media (max-width: 599px) {
              margin-bottom: 16px;
            }
          }

          input {
            &[type="radio"] {
              position: absolute; // take it out of document flow
              opacity: 0; // hide it

              & + label {
                position: relative;
                margin-bottom: 16px;
                padding: 0 0 0 30px;
                cursor: pointer;
                color: $main-text-color;
                font-size: 20px;
                line-height: 28px;
                font-weight: $fw-light;

                &:last-child {
                  margin-bottom: 0;
                }

                @media (max-width: 904px) {
                  padding-left: 34px;
                  font-size: 16px;
                  line-height: 24px;
                }

                a {
                  color: $red-dark-3;
                  border-bottom: 1px solid;
                }
              }

              // Box.
              & + label:before {
                content: "";
                position: absolute;
                top: 5px;
                left: 0;
                vertical-align: text-top;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                border: 2px solid $solid-60;

                @media (max-width: 599px) {
                  top: 3px;
                }
              }

              // Box focus
              &:focus-visible + label:before {
                box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
              }

              // Disabled state label.
              &:disabled + label {
                color: #b8b8b8;
                cursor: auto;
              }

              // Disabled box.
              &:disabled + label:before {
                box-shadow: none;
                background: #ddd;
              }

              // Checkmark image
              &:checked + label:before {
                content: "";
                border-color: $red-100;
              }

              &:checked + label:after {
                content: "";
                position: absolute;
                top: 10px;
                left: 5px;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background-color: $red-100;
                background-repeat: no-repeat;

                @media (max-width: 599px) {
                  top: 8px;
                }
              }
            }
          }
        }
      }

      &--checkboxes{
        label{
          margin-bottom: 16px;
          padding: 0 0 0 16px;

          @media (max-width: 599px) {
            padding-left: 0;
          }

          &:last-child{
            margin-bottom: 24px;

            @media (max-width: 599px) {
              margin-bottom: 16px;
            }
          }
        }
      }
      .form-drawer-button{
        // width: 100%;
        padding-right: 64px;
        font-size: 24px;
        line-height: 34px;
        font-weight: 300;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        flex: 1;
        border-bottom: 1px solid #909090;

        position: relative;
        margin-left: 32px;



        @media (max-width: 904px) {
          font-size: 20px;
          line-height: 30px;
        }

        @media (max-width: 599px) {
          border: none;
          padding-right: 34px;
          margin-left: 0;
        }

        &::after{
          content: "";
          display: block;
          position: absolute;
          top: calc(50% - 10px);
          right: 24px;
          width: 24px;
          height: 24px;
          background-image: url(./../images/icons/chevron/chevron-right-24-red.svg);

          @media (max-width: 904px) {
            right: 16px;
          }

          @media (max-width: 599px) {
            top: -12px;
            right: 7px;
          }
        }
      }

      &--select,
      .form-drawer-button {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media (max-width: 599px) {
          flex-direction: column;
        }
        .xp-input {
          border: none;
          padding: 2;
          width: 100%;
          font-weight: 300;
          &:focus-visible{
              outline: none;
          }
          &::placeholder {
            color: inherit;
          }
          @media (max-width: 599px) {
            width: 100%;
            padding-bottom: 12px;
            border-bottom: none;
          }
        }

        label{
          width: 22%;
          padding: 24px 0 24px 16px;

          @media (max-width: 599px) {
            width: 100%;
            margin-bottom: 4px;
            padding: 12px 0 0 0;
          }

          span{
            width: 100%;
            padding: 0;
            font-size: 20px;
            line-height: 28px;
            font-weight: 300;

            @media (max-width: 904px) {
              font-size: 13px;
              line-height: 24px;
              margin: 0;
            }
          }
        }

        select{
          position: absolute;
          width: 1px;
          height: 1px;
          opacity: 0;
          pointer-events: none;
        }

        .selected-item{
          position: relative;
          display: flex;
          align-items: center;
          width: calc(78% - 32px);
          margin-left: 32px;
          border-bottom: 1px solid $solid-60;
          cursor: pointer;

          @media (max-width: 599px) {
            width: 100%;
            margin-left: 0;
            padding-bottom: 12px;
            border-bottom: none;
          }

          p{
            width: 100%;
            padding-right: 64px;
            font-size: 24px;
            line-height: 34px;
            font-weight: 300;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

            @media (max-width: 904px) {
              font-size: 20px;
              line-height: 30px;
            }

            @media (max-width: 599px) {
              padding-right: 34px;
            }

            &::after{
              content: "";
              display: block;
              position: absolute;
              top: calc(50% - 10px);
              right: 24px;
              width: 24px;
              height: 24px;
              background-image: url(./../images/icons/chevron/chevron-right-24-red.svg);

              @media (max-width: 904px) {
                right: 16px;
              }

              @media (max-width: 599px) {
                top: -12px;
                right: 7px;
              }
            }
          }
        }
      }

      &--file{
        > div{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          @media (max-width: 599px) {
            flex-direction: column;
          }

          > span{
            width: 22%;
            padding: 24px 0 24px 16px;
            font-size: 20px;
            line-height: 28px;
            font-weight: $fw-light;

            @media (max-width: 599px) {
              width: 100%;
              padding: 12px 0 16px;
              font-size: 13px;
              line-height: 24px;
            }
          }

          .input-wrapper{
            width: calc(78% - 32px);
            margin-left: 32px;
            padding: 16px 0;

            @media (max-width: 599px) {
              width: 100%;
              margin-left: 0;
              padding: 0 0 16px;
            }

            input[type="file"]{
              width: 1px;
              height: 1px;
              position: absolute;
              opacity: 0;

              &:focus-visible{
                &+label{
                  box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.2);
                  border-width: 2px;
                  padding: 10px 39px;
                }
              }
            }

            label{
              padding: 11px 40px;
              border-radius: 8px;
              font-size: 18px;
              line-height: 26px;
              font-weight: $fw-book;
              display: inline-block;
              background-color: #E34D4E;
              color: $white;
              text-align: center;
              vertical-align: middle;
              user-select: none;
              border: 1px solid transparent;
              cursor: pointer;

              @media (max-width: 599px) {
                padding: 7px 30px;
                border-radius: 6px;
                font-size: 15px;
                line-height: 26px;
              }
            }

            .file-names{
              li{
                margin-bottom: 4px;
                font-size: 18px;
                line-height: 28px;
                font-weight: 300;

                @media (max-width: 599px) {
                  font-size: 16px;
                  line-height: 24px;
                }

                &:first-child{
                  margin-top: 8px;
                }

                &:last-child{
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }

      &--winorg-member-data .event-information__contact {
        margin-bottom: 0;
      }
    }

    .submit-wrapper{
      margin-top: 48px;
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: flex-end;
      gap: 24px;

      @media (max-width: 904px) {
        margin-top: 30px;
      }

      .btn{
        @media (max-width: 904px) {
          padding: 8px 16px;
          border-radius: 6px;
          font-size: 15px;
          line-height: 26px;
          font-weight: 400;
        }
      }
    }
  }
}
